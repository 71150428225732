import { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import dayjs from 'dayjs';
import { FormControl, InputLabel, Select, MenuItem, useMediaQuery, IconButton } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TablePagination from '@mui/material/TablePagination';
import { Link, useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CancelIcon from '@mui/icons-material/Cancel';
import OrderSearch from '../search/OrderSearch';
import FinishedAlert from '../finishView/FinishedAlert';

const API_PATH = process.env.REACT_APP_API_PATH;

const columnVisibilityModel = {
  id: true,
};

export default function SalesAmountManage() {
  LicenseInfo.setLicenseKey(
    '9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [start, setStart] = useState(dayjs().add(-20, 'day'));
  const [end, setEnd] = useState(dayjs().add(10, 'day'));
  const [saleAmount, setSaleAmount] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [freeShippingAmount, setFreeShippingAmount] = useState(0);
  const [replenishAmount, setReplenishAmount] = useState(0);
  const [freight, setFreight] = useState(0);
  const [realGetAmount, setRealGetAmount] = useState(0);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'customerName',
      headerName: '顧客名稱',
      width: 120,
      editable: false,
    },
    {
      field: 'commodityName',
      headerName: '賞品名稱',
      width: 180,
      editable: false,
    },
    {
      field: 'prizeName',
      headerName: '獎品名稱',
      width: 180,
      editable: false,
    },
    {
      field: 'status',
      headerName: '獎品狀態',
      width: 120,
      editable: false,
    },
    {
      field: 'costMoney',
      headerName: '消費金額',
      width: 120,
      editable: false,
    },
    {
      field: 'drawDate',
      headerName: '抽獎日期',
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value || new Date());
        const minutes = `0${date.getMinutes()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    {
      field: 'reclaimDate',
      headerName: '回收日期',
      width: 180,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    const response = await axios.get(
      `${API_PATH}/manufacturer/saleamount?start=${start.format('YYYY-MM-DD')}&end=${end.format(
        'YYYY-MM-DD'
      )}&pageNumber=${page + 1}&pageSize=${rowsPerPage}`
    );

    if (response.status === 200) {
      if (response.data.source !== null) {
        setRows(response.data.source.orders);
        setSaleAmount(response.data.source.saleAmount);
        setServiceFee(response.data.source.serviceFee);
        setFreight(response.data.source.freight);
        setFreeShippingAmount(response.data.source.freeShippingAmount);
        setRealGetAmount(response.data.source.realGetAmount);
        setReplenishAmount(response.data.source.replenishAmount);
        setTotalRows(response.data.totalItemCount);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  return (
    <>
      <Box sx={{ height: 600, width: '90%', margin: 'auto' }}>
        <Grid container spacing={2} style={{ marginBottom: '1%' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h2" component="h2">
              銷售列表
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" gutterBottom>
            <strong>金流走向說明:</strong>
            <br />
            銷售總額為顧客在該廠商消費總金額，
            <br />
            運費總額則是平台代替您向顧客收取的運費總額，
            <br />
            平台服務費為每筆訂單收取的服務費總額，
            <br />
            押金儲值為系統每日凌晨3點偵測押金不足時，自動從您當月貨款中扣除的金額(貨款足夠時才扣，若不夠則會直接寄信)，
            <br />
            免運總額則是廠商所發送之免運券抵免的運費總額，
            <br /><br />
            實收金額是平台方會實際匯款給廠商的金額 (銷售總額 - 平台服務費 + 運費總額 - 免運總額 - *押金儲值)，
            <br />
            廠商發票 (三聯式) 請款金額 公式為 (銷售總額 - 平台服務費 + 運費總額 - 免運總額- *押金儲值)<br />
            品名打: 貨款，統編:91214847、公司名稱:尊嘟假嘟工作室。
          </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              銷售總額 {saleAmount}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              平台服務費 {serviceFee}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              運費總額 {freight}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              免運總額 {freeShippingAmount}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              押金儲值 {replenishAmount}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" component="h6">
              實收金額 {realGetAmount}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ mt: 0, mr: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="起始日"
                value={start}
                onChange={(e) => setStart(dayjs(e))}
                renderInput={(params) => <TextField {...params} />}
              />
              ~
              <DatePicker
                label="終止日"
                value={end}
                onChange={(e) => setEnd(dayjs(e))}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Button startIcon={<SearchIcon />} onClick={fetchData}>
            搜尋
          </Button>
        </Grid>
        <DataGridPro
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          // onCellDoubleClick={handleMoneyOpenForm}
        />
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[50, 100, 200, 500, 2000]}
        />
      </Box>
    </>
  );
}
