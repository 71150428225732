/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import PercentIcon from '@mui/icons-material/Percent';
import { FormControl, InputLabel, Select, MenuItem, useMediaQuery } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DialogContentText from '@mui/material/DialogContentText';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactQuill, { Quill } from 'react-quill';
import * as Emoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import { v4 as uuidv4 } from 'uuid';
import { PhotoCamera } from '@mui/icons-material';
import CommoditySearch from '../search/CommoditySearch';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

Quill.register('modules/emoji', Emoji);
const toolbarOptions = {
  container: [
    ['bold', 'italic', 'underline', 'strike'], // 加粗、斜体、下划线、删除线
    ['blockquote', 'code-block'], // 引用、代码块
    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], // 列表
    [{ script: 'sub' }, { script: 'super' }], // 上下标
    [{ indent: '-1' }, { indent: '+1' }], // 缩进
    [{ direction: 'rtl' }], // 文字方向
    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
    [{ font: [] }], // 字体
    [{ align: [] }], // 对齐方式
    ['clean'], // 清除字体样式
    ['image', 'video'],
  ],
  handlers: { emoji: function () {} },
};

const eggOptions = [
  { value: '公仔', label: '公仔' },
  { value: '手辦', label: '手辦' },
  { value: '獵奇', label: '獵奇' },
  { value: '文創', label: '文創' },
];

const boxOptions = [
  { value: '玩具', label: '玩具' },
  { value: '拼圖', label: '拼圖' },
  { value: '零食', label: '零食' },
  { value: '動漫', label: '動漫' },
  { value: '驚嚇包', label: '驚嚇包' },
];

const luckyBagOptions = [
  { value: '廠商回饋', label: '廠商回饋' },
  { value: '出清', label: '出清' },
  { value: '折扣品', label: '折扣品' },
  { value: '送福利', label: '送福利' },
  { value: '瓜哥送幸福', label: '瓜哥送幸福' },
];

const ichiban = [
  { value: '動漫', label: '動漫' },
  { value: '遊戲', label: '遊戲' },
  { value: '電影', label: '電影' },
  { value: '經典聯名', label: '經典聯名' },
  { value: '機甲系列', label: '機甲系列' },
];

const special = [
  { value: '植栽', label: '植栽' },
  { value: '活體類', label: '活體類' },
  { value: '寵物用品', label: '寵物用品' },
  { value: '生活用品', label: '生活用品' },
  { value: '廠商自製', label: '廠商自製' },
];

const outwall = [
  { value: '精品代購', label: '精品代購' },
  { value: '韓團周邊', label: '韓團周邊' },
  { value: '保養品', label: '保養品' },
  { value: '化妝品', label: '化妝品' },
  { value: '潮流玩物', label: '潮流玩物' },
  { value: '運動用品', label: '運動用品' },
  { value: '服飾品', label: '服飾品' },
];

const threeC = [
  { value: '智慧型手機', label: '智慧型手機' },
  { value: '智能家電', label: '智能家電' },
  { value: '電動', label: '電動' },
  { value: '電玩周邊', label: '電玩周邊' },
];

const API_PATH = process.env.REACT_APP_API_PATH;

export default function CommodityManage() {
  LicenseInfo.setLicenseKey(
    '9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [rows, setRows] = useState([]);
  const [introduce, setIntroduce] = useState('');
  const [filterRows, setFilterRows] = useState([]);
  const [commodityFormOpen, setCommodityFormOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [commodityStatus, setCommodityStatus] = useState(1);
  const [filterCommodityStatus, setFilterCommodityStatus] = useState(1);
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [totalDrawOutTimes, setTotalDrawOutTimes] = useState(0);
  const [drawOut1Price, setDrawOut1Price] = useState(0);
  const [drawOut5Price, setDrawOut5Price] = useState(null);
  const [drawOut10Price, setDrawOut10Price] = useState(null);
  const [drawOutMultiplePriceStatus, setDrawOutMultiplePriceStatus] = useState(false);
  const [isUsePrizeName, setIsUsePrizeName] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [category, setCategory] = useState('扭蛋');
  const [commodityTotalAmount, setCommodityTotalAmount] = useState(0);
  const [commodityCategory, setCommodityCategory] = useState('公仔');

  const [prizeFormOpen, setPrizeFormOpen] = useState(false);
  const [prizes, setPrizes] = useState([]);
  const [selectedCommodityPrizeNameShowModel, setSelectedCommodityPrizeNameShowModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    id: 0,
    name: '複製範例',
  });
  const [newPrizeAmount, setNewPrizeAmount] = useState(0);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [discountOpen, setDiscountOpen] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [isMain, setIsMain] = useState(null);
  const [selectCommodityId, setSelectCommodityId] = useState(0);

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    // {
    //   field: 'imgUrl',
    //   headerName: '圖片',
    //   width: 70,
    //   editable: false,
    // },
    {
      field: 'manufacturerName',
      headerName: '廠商',
      width: 80,
      editable: false,
    },
    {
      field: 'isValidateDrawOutTimes',
      headerName: '抽籤驗證',
      width: 120,
      editable: false,
      renderCell: (params) => {
        let color;
        let message;
        switch (params.value) {
          case false:
            color = 'red';
            message = '籤數不合';
            break;
          case true:
            color = 'green';
            message = '已通過';
            break;
          default:
            color = 'orange';
            message = '未知錯誤';
        }

        return <span style={{ color }}>{message}</span>;
      },
    },
    {
      field: 'name',
      headerName: '賞品名稱',
      width: 120,
      editable: false,
    },
    {
      field: 'category',
      headerName: '賞品類別',
      width: 100,
      editable: false,
    },
    {
      field: 'commodityCategory',
      headerName: '賞品種類',
      width: 100,
      editable: false,
    },
    {
      field: 'status',
      headerName: '狀態',
      width: 100,
      editable: false,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return '上架中';
          case 2:
            return '下架中';
          case 3:
            return '準備中';
          default:
            return '未知';
        }
      },
    },
    {
      field: 'totalDrawOutTimes',
      headerName: '剩餘籤數',
      width: 90,
      editable: false,
    },
    {
      field: 'fixedTotalDrawOutTimes',
      headerName: '總抽籤數',
      width: 90,
      editable: false,
    },
    {
      field: 'creator',
      headerName: '創造者',
      sortable: false,
      width: 100,
    },
    {
      field: 'createDate',
      headerName: '創建時間',
      width: 130,
      editable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value || new Date());
        const minutes = `0${date.getMinutes()}`.slice(-2);
        const hours = `0${date.getHours()}`.slice(-2);
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      },
    },
    // {
    //   field: 'editor',
    //   headerName: '修改者',
    //   sortable: false,
    //   width: 100,
    // },
    // {
    //   field: 'editDate',
    //   headerName: '修改時間',
    //   width: 130,
    //   editable: false,
    //   valueFormatter: (params) => {
    //     if(params.value) {
    //       const date = new Date(params.value);
    //       const minutes = (`0${  date.getMinutes()}`).slice(-2);
    //       const hours = (`0${  date.getHours()}`).slice(-2);
    //       return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
    //     }
    //       return null;
    //   },
    // },
    {
      field: 'Operation',
      headerName: '操作',
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleCommodityClickOpen(false, params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="prize" onClick={() => handlePrizeClickOpen(params.row.id)}>
            <DashboardCustomizeIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDiscountClickOpen(params.row)}>
            <PercentIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => deleteCommodity(params.row.id)}>
            <DeleteForeverIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleDiscountClickOpen = (row) => {
    setSelectCommodityId(row.id);
    setDiscount(row.discount);
    setIsMain(row.isMain);
    setDiscountOpen(true);
    setCategory(row.category);
  };

  const handleDiscountClickClose = () => {
    setDiscountOpen(false);
  };

  const fetchTagsData = async (tags) => {
    const response = await axios.get(`${API_PATH}/manufacturer/tags?category=${category}`);
    
    if (!tags || tags.length === 0) {
      setSelectedTags([]);
    }
    
    if (response.status === 200) {
      let availableTags = response.data.source;
      
      if (tags && tags.length > 0) {
        // Create a Set of tag IDs for efficient lookup
        const tagIds = new Set(tags.map(tag => tag.id));
        
        // Filter out tags from availableTags that have IDs in tagIds
        availableTags = availableTags.filter(tag => !tagIds.has(tag.id));
      }
      
      setAvailableTags(availableTags);
    }
  };
  
  const handleChange = (event, newValue) => {
    if (newValue === null || newValue === undefined) return;
    setSelectedOption(newValue);
  };

  const deleteCommodity = async (id) => {
    const isConfirmed = window.confirm(
      '確定刪除該賞品嗎? 注意:會連同所有獎品一起刪除，若該賞品已有玩家抽過，則不可更改與刪除之'
    );
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await axios.delete(`${API_PATH}/manufacturer/commodity`, {
        params: { id }, // 将 id 作为查询参数传递
      });

      if (response.status === 200) {
        alert('商品删除成功');
        fetchData();
      }
    } catch (error) {
      console.error('删除商品失败:', error);
    }
  };

  const discountOrMainCommodity = async () => {
    try {

      if (discount !== '' && discount !== null) {
        if((isNaN(discount) || discount < 10 || discount > 99)) {
          alert('打折折扣必須為兩位數，範圍應為 10 至 99');
          return;
        }

        if(category === '福袋') { 
          alert('福袋無法打折');
          return; 
        }
      }

      const response = await axios.put(`${API_PATH}/manufacturer/discount`, {
        CommodityId: selectCommodityId,
        Discount: discount === '' ? null : discount,
        IsMain: isMain,
      });

      if (response.status === 200) {
        alert('促銷成功');
        handleDiscountClickClose();
        fetchData();
      } else {
        alert('促銷失敗: ' + response.data.Errors);
      }
    } catch (error) {
      console.error('促銷失敗:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target.result;
      };

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // 1. 驗證原始圖片尺寸
        const minWidth = 400;
        const minHeight = 400;
        if (width < minWidth || height < minHeight) {
          alert(`圖片尺寸過小。請確保寬度和高度都不小於 ${minWidth}px。`);
          setImage(null);
          setImagePreviewUrl('');
          return;
        }

        // 2. 驗證長寬比是否為 1:1，允許一定的誤差範圍
        const aspectRatio = width / height;
        const desiredRatio = 1.0; // 1:1
        const ratioTolerance = 0.01; // 誤差範圍
        if (Math.abs(aspectRatio - desiredRatio) > ratioTolerance) {
          alert('請上傳長寬比為 1:1 的圖片。');
          setImage(null);
          setImagePreviewUrl('');
          return;
        }

        // 3. 定義縮放的最大尺寸
        const maxSize = 800; // 最大尺寸為 800px

        let newWidth = width;
        let newHeight = height;

        // 計算新的尺寸，保持縱橫比
        if (newWidth > newHeight) {
          if (newWidth > maxSize) {
            newHeight = (newHeight * maxSize) / newWidth;
            newWidth = maxSize;
          }
        } else {
          if (newHeight > maxSize) {
            newWidth = (newWidth * maxSize) / newHeight;
            newHeight = maxSize;
          }
        }

        // 創建一個 Canvas 元素
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // 將圖片繪製到 Canvas 上
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // 將 Canvas 轉換為 Blob（壓縮圖片）
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              alert('圖片壓縮失敗，請重試。');
              return;
            }

            // 創建一個新的 File 對象
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg', // 或者 'image/png'，根據需求調整
              lastModified: Date.now(),
            });

            // 讀取壓縮後的圖片作為預覽
            const previewReader = new FileReader();
            previewReader.onloadend = () => {
              setImage(compressedFile);
              setImagePreviewUrl(previewReader.result);
            };
            previewReader.readAsDataURL(compressedFile);
          },
          'image/jpeg', // 或者 'image/png'，根據需求調整
          0.5 // 壓縮品質，0 到 1 之間，數值越低壓縮率越高
        );
      };

      img.onerror = () => {
        alert('無效的圖片文件，請嘗試其他文件。');
        setImage(null);
        setImagePreviewUrl('');
      };

      // 讀取原始文件為 Data URL
      reader.readAsDataURL(file);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIntroduce((prev) => `${prev}<br/>`);
    }
  };

  const handleCopy = () => {
    setId(0);
    setName(selectedOption.name);
    setCategory(selectedOption.category);
    setCommodityStatus(selectedOption.status);
    setDrawOut1Price(selectedOption.drawOut1Price);
    setDrawOut5Price(selectedOption.drawOut5Price);
    setDrawOut10Price(selectedOption.drawOut10Price);
    setDrawOutMultiplePriceStatus(selectedOption.drawOutMultiplePriceStatus);
    setImage(selectedOption.imgUrl);
    setTotalDrawOutTimes(selectedOption.fixedTotalDrawOutTimes);
    setImagePreviewUrl(selectedOption.imgUrl);
    setIntroduce(extractTextFromHTML(selectedOption.introduce));
    setSelectedCommodityPrizeNameShowModel(isUsePrizeName);
  };

  const extractTextFromHTML = (htmlString) => {
    // 创建一个临时的 DOM 元素
    const tempElement = document.createElement('div');

    // 将 HTML 字符串设置为临时元素的内容
    tempElement.innerHTML = htmlString;

    // 提取文本内容
    return tempElement.textContent || tempElement.innerText || '';
  };

  const getCommodityCategoryOptions = (category) => {
    switch (category) {
      case '扭蛋':
        return eggOptions;
      case '盲盒':
        return boxOptions;
      case '福袋':
        return luckyBagOptions;
      case '一番賞':
        return ichiban;
      case '特別賞':
        return special;
      case '牆外世界':
        return outwall;
      case '數碼世界':
        return threeC;
      default:
        return [];
    }
  };

  const handleSubmit = async () => {
    const request = {
      id,
      Name: name,
      Category: category,
      ManufacturerId: localStorage.getItem('id'),
      Status: commodityStatus,
      DrawOut1Price: drawOut1Price,
      DrawOut5Price: drawOut5Price,
      DrawOut10Price: drawOut10Price,
      DrawOutMultiplePriceStatus: drawOutMultiplePriceStatus,
      Img: image,
      TotalDrawOutTimes: totalDrawOutTimes,
      Introduce: introduce,
      CommodityCategory: commodityCategory,
      Tags: selectedTags.map((tag) => tag.id),
      isUsePrizeName: isUsePrizeName,
      IsCopy: isCopy,
    };
    if (category === '福袋') {
      if (drawOut1Price != 1) {
        alert('福袋價格必須為1張');
        return;
      }

      if (drawOut5Price !== null || drawOut10Price !== null || drawOutMultiplePriceStatus) {
        alert('不得開放多抽');
        return;
      }
    }

    if (
      !introduce ||
      !name ||
      !category ||
      commodityStatus == null ||
      drawOut1Price == null ||
      drawOutMultiplePriceStatus == null ||
      (!image && id === 0) ||
      totalDrawOutTimes == null
    ) {
      alert('請確保資料無缺');
      return;
    }
    if (drawOutMultiplePriceStatus) {
      if (drawOut5Price === null || drawOut10Price === null || drawOut5Price === '0' || drawOut10Price === '0') {
        alert('多重價格啟用時 五連抽與十連抽價格不得為0');
        return;
      }
    }
    const data = new FormData();
    Object.keys(request).forEach((key) => {
      if (key !== 'Tags' && request[key] !== null && request[key] !== undefined) {
        data.append(key, request[key]);
      }
    });

    // 5. 将 Tags 数组直接添加到 FormData 中
    request.Tags.forEach((tagId) => {
      data.append('Tags', tagId); // 每个 tagId 作为单独的 form-data 字段添加
    });
    const response = await axios.post(`${API_PATH}/manufacturer/commodity`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      alert('成功');
      setCommodityFormOpen(false);
      fetchData();
      fetchTagsData();
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_PATH}/manufacturer/commodities?pageNumber=${
          page + 1
        }&status=${filterCommodityStatus}&pageSize=${rowsPerPage}`
      );

      if (response.status === 200) {
        setRows(response.data.source);
        setFilterRows(response.data.source);
        setTotalRows(response.data.totalItemCount);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  const fetchPrizeData = useCallback(async (id) => {
    localStorage.setItem('commodityId', id);
    try {
      const response = await axios.get(`${API_PATH}/manufacturer/prizes?commodityId=${id}`);
      if (response.status === 200) {
        setPrizes([...response.data.source]);
        // totalPrizeAmount 将通过 useMemo 计算，无需在此更新
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  const totalPrizeAmount = useMemo(() => {
    const prizesAmount = prizes.reduce((total, prize) => {
      if (prize.prizeLevel !== 27) {
        return total + (Number(prize.amount) || 0);
      }
      return total;
    }, 0);
    return prizesAmount + (Number(newPrizeAmount) || 0);
  }, [prizes, newPrizeAmount]);

  const setPrizeAmount = useCallback((id, newAmount) => {
    if (id === 0) {
      setNewPrizeAmount(Number(newAmount));
    } else {
      setPrizes((prevPrizes) => {
        const updatedPrizes = prevPrizes.map((prize) =>
          prize.id === id ? { ...prize, amount: Number(newAmount) } : prize
        );
        return updatedPrizes;
      });
    }
  }, []);

  const handleCommodityClickOpen = (bool, row) => {
    if (bool) {
      setId(0);
      setName('');
      setCategory('扭蛋');
      setCommodityCategory('公仔');
      setCommodityStatus(3);
      setDrawOut1Price(0);
      setDrawOut5Price(null);
      setDrawOut10Price(null);
      setDrawOutMultiplePriceStatus(false);
      setImage(null);
      setTotalDrawOutTimes(0);
      setImagePreviewUrl('');
      setIntroduce('');
      setSelectedTags([]);
    } else {  
      setId(row.id);
      setName(row.name);
      setCategory(row.category);
      setCommodityCategory(row.commodityCategory);
      setCommodityStatus(row.status);
      setDrawOut1Price(row.drawOut1Price);
      setDrawOut5Price(row.drawOut5Price);
      setDrawOut10Price(row.drawOut10Price);
      setDrawOutMultiplePriceStatus(row.drawOutMultiplePriceStatus);
      setTotalDrawOutTimes(row.totalDrawOutTimes);
      setImagePreviewUrl(row.imgUrl);
      setIntroduce(row.introduce);
      setSelectedTags(row.tags);
      fetchTagsData(row.tags);
    }
    setCommodityFormOpen(true);
  };

  const handleTagAdd = (tag) => {
    setAvailableTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
    setSelectedTags((prevTags) => [...prevTags, tag]);
  };

  // 处理标签移除
  const handleTagRemove = (tag) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t.id !== tag.id));
    setAvailableTags((prevTags) => [...prevTags, tag]);
  };

  const handlePrizeClickOpen = (commodityId) => {
    const selectedCommodity = rows.find((item) => item.id === commodityId);
    setSelectedCommodityPrizeNameShowModel(selectedCommodity.isUsePrizeName);
    setCommodityTotalAmount(selectedCommodity.fixedTotalDrawOutTimes);
    fetchPrizeData(commodityId);
    setPrizeFormOpen(true);
  };

  const handlePrizeClickClose = () => {
    setPrizes([]);
    setPrizeFormOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    switch (category) {
      case '扭蛋':
        setCommodityCategory('公仔');
        break;
      case '盲盒':
        setCommodityCategory('玩具');
        break;
      case '福袋':
        setCommodityCategory('廠商回饋');
        break;
      case '一番賞':
        setCommodityCategory('動漫');
        break;
      case '特別賞':
        setCommodityCategory('廠商自製');
        break;
      case '牆外世界':
        setCommodityCategory('精品代購');
        break;
      case '數碼世界':
        setCommodityCategory('智能家電');
        break;
      default:
        break;
    }
    fetchTagsData();
  }, [category]);

  useEffect(() => {}, [selectedTags]);

  useEffect(() => {
    if (!drawOutMultiplePriceStatus) {
      setDrawOut5Price(null);
      setDrawOut10Price(null);
    }
  }, [drawOutMultiplePriceStatus]);

  return (
    <>
      <Box sx={{ height: 600, width: '90%', margin: 'auto' }}>
        <Grid container spacing={2} style={{ marginBottom: '1%' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h2" component="h2">
              賞品列表
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '100%', maxWidth: '800px' }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h6">使用說明 必須看完再上架</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                    <strong>2024-12-04溫馨提醒</strong> 因補助破處任務，請勿惡意上架高單抽價格商品，並惡意洗任務首抽洗錢，系統會偵測到
                    <br />
                    <br />
                    <strong>2024-11-21補充說明</strong> 目前未開放自動多抽，若擔心顧客無法多抽，請上架時自行開啟多抽，價格不一定要優惠沒關係
                    <br />
                    <br />
                    <strong>1.</strong> 若偶爾出現{' '}
                    <code>Cannot access a disposed object. Object name: 'System.Net.Http.HttpClient'</code> 類似問題，
                    請稍後再按一次按鈕就成功了，因第三方服務問題，非我能控制。若還是不行，請聯絡我，並告知當時發生的情況，
                    若能給我錄影更好，謝謝。
                    <br />
                    <br />
                    <strong>2.</strong> 若是多大獎的套，需將大獎都打勾。假設有 A 跟 B 為大獎，只勾 A 的情況下，
                    客人先抽到了 A，套就下架了，剩下的 B 賞就沒得抽。
                    <br />
                    會造成的疑慮是：配率很好，且還有大獎 B 的情況下卻不能抽， 或是客人為了抽 B 賞才抽，但抽到了
                    A，套就下架了。這類爭議在賞翻天太多了， 因為很多廠商做多大獎的套，卻不好好勾選。
                    <br />
                    正常情況下，多大獎的套，大獎都需勾選售完下架。且勾選的大獎都被抽出後，剩小獎才自動下架，
                    這樣才不會有爭議。
                    <br />
                    <br />
                    <strong>3.</strong>{' '}
                    若選擇顯示獎品名稱，則抽籤時會顯示獎品名稱。若不選擇，則會顯示賞品等級，但請注意獎品名稱別超過 6
                    個字。
                    <br />
                    <br />
                    <strong>4.</strong> 請詳細填寫賞品介紹和獎品介紹。
                    <br />
                    <br />
                    <strong>5.</strong> 圖片請幫我傳優質點的，不然會影響大家的版面。
                    <br />
                    <br />
                    <strong>6.</strong>{' '}
                    若有找不到的標籤請密我申請，剛新增商品時都會在準備中，請確認真的沒問題再改上架，若因此遭受損失自行負責。
                    <br />
                    <br />
                    <strong>7.</strong> 單筆訂單尺寸 45 （含）以內🉑使用711店到店。 超過125（含）只能選擇宅配。
                    運費價格取單筆訂單最高的賞品運費價格去做計算。
                    <br />
                    例： 單筆訂單 有2個A賞+1個B賞：
                    A賞運費價格150，B賞運費價格120，此筆訂單運費則挑150最高價格最為此單運費。
                    <br />
                    不同廠商的賞品不得合併出貨，若未處理獎品超過30天，則廠商會自動回收該獎品!!
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <CommoditySearch rows={rows} setFilterRows={setFilterRows} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', marginBottom: '2%' }}>
            <FormControl style={{ marginRight: '2%' }}>
              <InputLabel id="stock-amount-label">搜尋狀態</InputLabel>
              <Select
                labelId="stock-amount-label"
                id="stock-amount-select"
                value={filterCommodityStatus}
                label="搜尋商品狀態"
                onChange={(e) => setFilterCommodityStatus(e.target.value)}
              >
                <MenuItem value={1}>上架中</MenuItem>
                <MenuItem value={2}>已下架</MenuItem>
                <MenuItem value={3}>準備中</MenuItem>
              </Select>
            </FormControl>
            <Button variant="outlined" endIcon={<SearchIcon />} onClick={fetchData}>
              搜尋
            </Button>
            <Stack direction="row" spacing={2} style={{ marginLeft: '2%' }}>
              <Button
                variant="outlined"
                startIcon={<AddToPhotosIcon />}
                onClick={() => handleCommodityClickOpen(true, null)}
              >
                上架商品
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <DataGridPro rows={filterRows} columns={columns} disableRowSelectionOnClick />
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[50, 100, 200, 500]}
        />
      </Box>
      <Dialog
        open={commodityFormOpen}
        onClose={() => setCommodityFormOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '50%',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle style={{ justifyContent: 'center', display: 'flex', fontSize: '16px' }}>
          {id === 0 ? '上架賞品' : '修改賞品'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="stock-amount-label">商品狀態</InputLabel>
                  <Select
                    labelId="stock-amount-label"
                    id="stock-amount-select"
                    value={commodityStatus}
                    label="商品狀態"
                    onChange={(e) => setCommodityStatus(e.target.value)}
                  >
                    <MenuItem value={1} disabled={id === 0}>
                      上架中
                    </MenuItem>
                    <MenuItem value={2} disabled={id === 0}>
                      已下架
                    </MenuItem>
                    <MenuItem value={3}>準備中</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  size="small"
                  label="賞品名稱"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  helperText="請不要將不同套(賞品)取一樣的名子，除非兩者內容物完完全全相同"
                  disabled={isCopy}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="stock-amount-label">賞品種類</InputLabel>
                  <Select
                    labelId="stock-amount-label"
                    id="stock-amount-select"
                    value={category}
                    label="賞品種類"
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <MenuItem value="扭蛋">扭蛋</MenuItem>
                    <MenuItem value="盲盒">盲盒</MenuItem>
                    <MenuItem value="福袋">福袋</MenuItem>
                    <MenuItem value="一番賞">一番賞</MenuItem>
                    <MenuItem value="特別賞">特別賞</MenuItem>
                    <MenuItem value="牆外世界">牆外世界</MenuItem>
                    <MenuItem value="數碼世界">數碼世界</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="stock-amount-label">賞品類型</InputLabel>
                  <Select
                    labelId="stock-amount-label"
                    id="stock-amount-select"
                    value={commodityCategory}
                    label="賞品類型"
                    onChange={(e) => setCommodityCategory(e.target.value)}
                  >
                    {getCommodityCategoryOptions(category).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={isMobile ? 12 : 2} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  size="small"
                  label="總抽籤數"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={totalDrawOutTimes}
                  onChange={(e) => setTotalDrawOutTimes(e.target.value)}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 2} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <FormControlLabel
                  style={{ marginBottom: '15%' }}
                  control={
                    <Checkbox
                      checked={isUsePrizeName}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(e) => setIsUsePrizeName(e.target.checked)}
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
                      燈籠顯示獎品名
                    </Typography>
                  }
                />
              </Grid>
              {category !== '福袋' && (
                <Grid item xs={isMobile ? 12 : 2} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                  <FormControlLabel
                    style={{ marginBottom: '15%' }}
                    control={
                      <Checkbox
                        checked={drawOutMultiplePriceStatus}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => setDrawOutMultiplePriceStatus(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
                        多重售價
                      </Typography>
                    }
                  />
                </Grid>
              )}
              <Grid item xs={isMobile ? 12 : 2} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  size="small"
                  label="一抽價格"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={drawOut1Price}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (category !== '活動賞') {
                      setDrawOut1Price(value);
                    } else {
                      setDrawOut1Price(1); // 如果不等于1，就设置为1
                    }
                  }}
                />
              </Grid>
              {category !== '活動賞' && (
                <>
                  <Grid
                    item
                    xs={isMobile ? 12 : 2}
                    sx={{ mt: 1 }}
                    style={{ justifyContent: 'center', display: 'flex' }}
                  >
                    <TextField
                      fullWidth
                      id="outlined-number"
                      size="small"
                      label="五連抽價格"
                      helperText={drawOutMultiplePriceStatus === false ? '目前不得更改' : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: drawOutMultiplePriceStatus === false,
                      }}
                      value={drawOut5Price ?? 0}
                      onChange={(e) => setDrawOut5Price(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 12 : 2}
                    sx={{ mt: 1 }}
                    style={{ justifyContent: 'center', display: 'flex' }}
                  >
                    <TextField
                      fullWidth
                      id="outlined-number"
                      size="small"
                      label="十連抽價格"
                      helperText={drawOutMultiplePriceStatus === false ? '目前不得更改' : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: drawOutMultiplePriceStatus === false,
                      }}
                      value={drawOut10Price ?? 0}
                      onChange={(e) => setDrawOut10Price(e.target.value)}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <InputLabel id="stock-amount-label" style={{ marginLeft: '10%' }}>
                  可添加標籤群
                </InputLabel>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                  {availableTags.map((tag) => (
                    <Chip
                      style={{ marginTop: '1%' }}
                      key={tag.id}
                      label={tag.tagName}
                      color="success"
                      variant="outlined"
                      size="small"
                      onClick={() => handleTagAdd(tag)} // 點擊添加到已選擇標籤
                    />
                  ))}
                </Stack>
              </Grid>

              {/* 標籤提示 */}

              <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <InputLabel id="stock-amount-label" style={{ marginLeft: '10%', color: 'red' }}>
                  已添加標籤群
                </InputLabel>
              </Grid>

              {/* 已添加標籤群 */}
              <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                  {selectedTags.map((tag) => (
                    <Chip
                      style={{ marginTop: '1%' }}
                      key={tag.id}
                      label={tag.tagName}
                      variant="outlined"
                      color="warning"
                      size="small"
                      onClick={() => handleTagRemove(tag)} // 點擊從已選擇標籤中移除
                    />
                  ))}
                </Stack>
              </Grid>
              {id === 0 ? (
                <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCopy}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => setIsCopy(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
                        是否複製
                      </Typography>
                    }
                  />
                </Grid>
              ) : null}
              {isCopy ? (
                <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                  <Item style={{ background: 'transparent' }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={rows}
                      sx={{ width: 200 }}
                      value={selectedOption}
                      onChange={handleChange}
                      getOptionLabel={(option) => option.name}
                      // 設置renderInput來渲染輸入元件
                      renderInput={(params) => <TextField {...params} label="需複製之原賞品" />}
                    />
                  </Item>
                  <Button onClick={handleCopy} startIcon={<ContentCutIcon />}>
                    複製
                  </Button>
                </Grid>
              ) : null}

              <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Grid>
              {!image && id === 0 && (
                <>
                  <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                    <Typography>尚未上傳賞品圖 800px * 800px </Typography>
                  </Grid>
                </>
              )}
              {(image || id !== 0) && (
                <>
                  <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                    <Typography style={{ fontWeight: 'bold' }}>預覽圖片</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src={imagePreviewUrl} alt="Preview" style={{ maxHeight: '300px', marginTop: '0px' }} />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }} style={{ justifyContent: 'center', display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>賞品介紹</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
              <ReactQuill
                theme="snow"
                value={introduce}
                onKeyDown={handleKeyDown}
                onChange={setIntroduce}
                modules={{
                  toolbar: toolbarOptions,
                  'emoji-toolbar': true,
                  'emoji-textarea': false,
                  'emoji-shortname': true,
                }}
              />
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} startIcon={<AddToHomeScreenIcon />}>
            送出
          </Button>
          <Button onClick={() => setCommodityFormOpen(false)} startIcon={<ClearIcon />}>
            取消
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={prizeFormOpen}
        onClose={() => setPrizeFormOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '100%' : '50%',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" flexDirection="column" alignItems="center">
            {/* 标题 */}
            <Typography variant="h6" gutterBottom>
              獎品管理
            </Typography>

            {/* 当前奖品总籤数 */}
            <Typography variant="body1">
              目前獎品總籤數為: <strong>{totalPrizeAmount}</strong>
            </Typography>

            {/* 該賞品初始總籤數 */}
            <Typography variant="body1">
              該賞品初始總籤數: <strong>{commodityTotalAmount}</strong>
            </Typography>

            {/* 状态信息 */}
            <Box display="flex" alignItems="center" mt={2}>
              {totalPrizeAmount === commodityTotalAmount ? (
                <>
                  <CheckCircle color="success" />
                  <Typography variant="body1" color="success.main" ml={0.5}>
                    籤數符合 - 可改上架中
                  </Typography>
                </>
              ) : (
                <>
                  <ErrorIcon color="error" />
                  <Typography variant="body1" color="error.main" ml={0.5}>
                    籤數不符合 - 不可上架
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PrizeForm
            key={0}
            data={null}
            id={0}
            fetchPrizeData={fetchPrizeData}
            isMobile={isMobile}
            setPrizeAmount={setPrizeAmount}
            showNameModel={selectedCommodityPrizeNameShowModel}
          />
          {prizes
            .filter((prize) => prize.id !== 0)
            .map((prize) => (
              <PrizeForm
                key={prize.id}
                data={prize}
                id={prize.id}
                fetchPrizeData={fetchPrizeData}
                isMobile={isMobile}
                setPrizeAmount={setPrizeAmount}
                showNameModel={selectedCommodityPrizeNameShowModel}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePrizeClickClose}>取消</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={discountOpen}
        onClose={handleDiscountClickClose}
        maxWidth="xs" // 限制對話框的最大寬度
        fullWidth // 確保對話框寬度不會超過最大寬度
      >
        <DialogTitle>商品打折</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            如果要取消打折，請別填任何東西，若有輸入數字，請輸入兩位數(舉例說明:輸入65 則該商品打65折)
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="打折折數"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            type="number"
            variant="standard"
            fullWidth // 讓輸入框寬度填滿
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  checked={isMain}
                  onChange={(e) => setIsMain(e.target.checked)} // 綁定事件處理程序
                />
              }
              label="是否為主打商品"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscountClickClose}>取消</Button>
          <Button type="button" onClick={discountOrMainCommodity}>
            修改
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const PrizeForm = React.memo(function PrizeForm({ data, id, fetchPrizeData, isMobile, setPrizeAmount, showNameModel }) {
  const [introduce, setIntroduce] = useState(data && data.introduce !== undefined ? data.introduce : '');
  const [prizeName, setPrizeName] = useState(data && data.prizeName !== undefined ? data.prizeName : '');
  const [prizeLevel, setPrizeLevel] = useState(data && data.prizeLevel !== undefined ? data.prizeLevel : 0);
  const [sort, setSort] = useState(data && data.sort !== undefined ? data.sort : 0);
  const [size, setSize] = useState(data && data.size !== undefined ? data.size : 0);
  const [amount, setAmount] = useState(data && data.amount !== undefined ? data.amount : 0);
  const [reclaimPrice, setReclaimPrice] = useState(data && data.reclaimPrice !== undefined ? data.reclaimPrice : 0);
  const [freight, setFreight] = useState(data && data.freight !== undefined ? data.freight : 0);
  const [isOverAfterSoldOut, setIsOverAfterSoldOut] = useState(
    data && data.isOverAfterSoldOut !== undefined ? data.isOverAfterSoldOut : false
  );
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(data && data.imgUrl !== undefined ? data.imgUrl : '');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();

      // 當文件讀取完成後設置圖片源
      reader.onload = (event) => {
        img.src = event.target.result;
      };

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // 1. 驗證原始圖片尺寸
        const minWidth = 400;
        const minHeight = 400;
        if (width < minWidth || height < minHeight) {
          alert(`圖片尺寸過小。請確保寬度和高度都不小於 ${minWidth}px。`);
          setImage(null);
          setImagePreviewUrl('');
          return;
        }

        // 2. 驗證長寬比是否為 1:1，允許一定的誤差範圍
        const aspectRatio = width / height;
        const desiredRatio = 1.0; // 1:1
        const ratioTolerance = 0.01; // 誤差範圍
        if (Math.abs(aspectRatio - desiredRatio) > ratioTolerance) {
          alert('請上傳長寬比為 1:1 的圖片。');
          setImage(null);
          setImagePreviewUrl('');
          return;
        }

        // 3. 創建一個 Canvas 元素並進行縮放
        const canvas = document.createElement('canvas');
        const maxSize = 800; // 最大尺寸為 800px

        let newWidth = width;
        let newHeight = height;

        // 計算新的尺寸，保持縱橫比
        if (newWidth > newHeight) {
          if (newWidth > maxSize) {
            newHeight = (newHeight * maxSize) / newWidth;
            newWidth = maxSize;
          }
        } else {
          if (newHeight > maxSize) {
            newWidth = (newWidth * maxSize) / newHeight;
            newHeight = maxSize;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        // 將圖片繪製到 Canvas 上
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // 將 Canvas 轉換為 Blob（壓縮圖片）
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              alert('圖片壓縮失敗，請重試。');
              return;
            }

            // 創建一個新的 File 對象
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg', // 或者 'image/png'，根據需求調整
              lastModified: Date.now(),
            });

            // 讀取壓縮後的圖片作為預覽
            const previewReader = new FileReader();
            previewReader.onloadend = () => {
              setImage(compressedFile);
              setImagePreviewUrl(previewReader.result);
              // console.log(previewReader.result);
            };
            previewReader.readAsDataURL(compressedFile);
          },
          'image/jpeg', // 或者 'image/png'，根據需求調整
          0.5 // 壓縮品質，0 到 1 之間，數值越低壓縮率越高
        );
      };

      img.onerror = () => {
        alert('無效的圖片文件，請嘗試其他文件。');
        setImage(null);
        setImagePreviewUrl('');
      };

      // 讀取原始文件為 Data URL
      reader.readAsDataURL(file);
    }
  };
  // eslint-disable-next-line consistent-return
  const handleDeletePrize = async (id) => {
    try {
      const response = await axios.delete(`${API_PATH}/manufacturer/prize`, {
        params: { id },
      });
      if (response.status === 200) {
        fetchPrizeData(localStorage.getItem('commodityId'));
        alert('獎品刪除成功');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('無此權限');
      }
      return null;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIntroduce((prev) => `${prev}<br/>`);
    }
  };

  const getStringLength = (str) => {
    return str.replace(/[^\x00-\xff]/g, 'xx').length / 2;
  };

  const handleSubmit = async (info) => {
    const request = {
      id,
      CommodityId: localStorage.getItem('commodityId'),
      PrizeName: prizeName,
      PrizeLevel: prizeLevel,
      Img: image,
      ImgUrl: info ? info.imgUrl : '',
      Sort: sort,
      Size: size,
      Amount: amount,
      ReclaimPrice: reclaimPrice,
      Freight: freight,
      IsOverAfterSoldOut: isOverAfterSoldOut,
      Introduce: introduce,
    };
    if (showNameModel) {
      if (getStringLength(prizeName) > 6) {
        alert('啟用顯示獎品名模式 獎品名稱不能超過 6 個中文字的長度~');
        return;
      }
    }

   

    if (
      !introduce ||
      !prizeName ||
      prizeLevel === undefined ||
      sort === undefined ||
      (!image && id === 0) ||
      size === undefined ||
      amount === undefined ||
      freight === undefined
    ) {
      alert('請確保資料無缺');
      return;
    }

    if(size > 135) {
      alert('單一商品運送尺寸請小於等於135');
      return;
    }

    if (reclaimPrice === undefined || reclaimPrice <= 0) {
      alert('回收價格請大於0，避免商品未處理過久自動回收，顧客有損失');
      return;
    }

    const formData = new FormData();
    Object.keys(request).forEach((key) => {
      if (request[key] !== null && request[key] !== undefined) {
        formData.append(key, request[key]);
      }
    });

    try {
      const response = await axios.post(`${API_PATH}/manufacturer/prize`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        alert('成功');
        fetchPrizeData(localStorage.getItem('commodityId'));
      }
    } catch (error) {
      alert(error.response?.data || '提交失败');
    }
  };

  useEffect(() => {
    setPrizeAmount(id, amount);
  }, [id, amount, setPrizeAmount]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Typography variant="body2" style={{ fontSize: '16px', marginBottom: '1%' }}>
        {id === 0 ? '尚未新增之' : ''}獎品-{data && data.id !== undefined ? data.id : 0}
      </Typography>
      <Grid container spacing={2}>
        {/* 獎品名稱 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="small"
            label="獎品名稱"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setPrizeName(e.target.value)}
            value={prizeName}
            helperText="若啟用顯示獎品名稱 則長度不得大於6位中文字"
          />
        </Grid>
        {/* 獎品等級 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <FormControl fullWidth size="小">
            <InputLabel id="prize-level-label">獎品等級</InputLabel>
            <Select
              labelId="prize-level-label"
              id="prize-level-select"
              value={prizeLevel}
              label="獎品等級"
              onChange={(e) => setPrizeLevel(e.target.value)}
            >
              <MenuItem value={0}>SP級</MenuItem>
              <MenuItem value={27}>Last One</MenuItem>
              {MenuItems()}
            </Select>
          </FormControl>
        </Grid>
        {/* 排序 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="小"
            label="排序"
            InputLabelProps={{
              shrink: true,
            }}
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
        </Grid>
        {/* 尺寸 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="小"
            label="尺寸"
            InputLabelProps={{
              shrink: true,
            }}
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        </Grid>
        {/* 數量 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="小"
            label="數量"
            InputLabelProps={{
              shrink: true,
            }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        {/* 回收價格 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="小"
            label="回收價格"
            InputLabelProps={{
              shrink: true,
            }}
            value={reclaimPrice}
            onChange={(e) => setReclaimPrice(e.target.value)}
          />
        </Grid>
        {/* 運費 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <TextField
            fullWidth
            id="outlined-number"
            size="小"
            label="運費"
            InputLabelProps={{
              shrink: true,
            }}
            value={freight}
            onChange={(e) => setFreight(e.target.value)}
          />
        </Grid>
        {/* 是否售完即下架 */}
        <Grid item xs={isMobile ? 12 : 3} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isOverAfterSoldOut}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => setIsOverAfterSoldOut(e.target.checked)}
              />
            }
            label={
              <Typography variant="body2" style={{ fontFamily: 'Arial', fontSize: '12px' }}>
                是否售完即下架
              </Typography>
            }
          />
        </Grid>
        {/* 圖片上傳 */}
        <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`contained-button-file${id}`}
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor={`contained-button-file${id}`}>
            <IconButton color="primary" aria-label="upload picture" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
        </Grid>
        {!image && id === 0 && (
          <>
            <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
              <Typography>尚未上傳獎品圖 800px * 800px </Typography>
            </Grid>
          </>
        )}
        {(image || id !== 0) && (
          <>
            <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>預覽圖片</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
              <img src={imagePreviewUrl} alt="Preview" style={{ maxHeight: '300px', marginTop: '0px' }} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
        <Button type="button" endIcon={<SaveAsIcon />} onClick={() => handleSubmit(data)}>
          {id === 0 ? '新增' : '修改'}
        </Button>
        {id !== 0 && (
          <>
            <Button type="button" endIcon={<DeleteForeverIcon />} onClick={() => handleDeletePrize(id)}>
              刪除
            </Button>
          </>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }} style={{ justifyContent: 'center', display: 'flex' }}>
        <Typography style={{ fontWeight: 'bold' }}>獎品介紹-盡可能詳細</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
        <ReactQuill
          theme="snow"
          onKeyDown={handleKeyDown}
          value={introduce}
          onChange={setIntroduce}
          modules={{
            toolbar: toolbarOptions,
            'emoji-toolbar': true,
            'emoji-textarea': false,
            'emoji-shortname': true,
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}>
        <hr style={{ width: '100%', color: 'black', backgroundColor: 'black', height: 8 }} />
      </Grid>
    </Box>
  );
});

const MenuItems = () => {
  const menuItems = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 26; i++) {
    menuItems.push(
      <MenuItem key={i} value={i}>
        {String.fromCharCode(64 + i)}級
      </MenuItem>
    );
  }
  return menuItems;
};
